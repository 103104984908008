/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Root variables */
:root {
  --theme-color: #582A26;
  --primary-color: #AF9451;
  --dark-color: #212121;
  --card-bg: #F4F2ED;
  --gray-bg: #F5F5F5;
}

/* Global styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  background-color: #ffffff;
}

/* Headings */
h1,
h2 {
  color: var(--theme-color);
}
p{
  line-height: 1.8;
  padding: 1rem 0 0rem;
  font-weight: 500;
}

a{
  color: var(--dark-color);
  text-decoration: none;
}

/* Font */

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

/* Logo */
.logo {
  max-width: 100%;
  height: 117px;
}

.logo-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ant-menu .ant-menu-item-selected .ant-menu-title-content{
  color: var(--primary-color)!important;
}
.ant-menu .ant-menu-item-selected::after{
  border-bottom-color: var(--primary-color)!important;
}
/* Search box */
.search-box {
  height: 45px;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
}

.search-box .ant-input {
  border: none;
  height: 42px;
}

.search-box .ant-input-group-addon {
  background: white;
  padding: 0px 2px !important;
}

.search-box .ant-btn {
  background: #F6EFDD;
  color: black;
  height: 38px;
  border-radius: 20px;
}

.search-box .anticon {
  color: var(--primary-color);
  font-size: 20px;
}

/* Placeholder */
::placeholder {
  color: var(--dark-color) !important;
  opacity: 1 !important;
}

/* Button hover */
.ant-btn:hover {
  background: var(--theme-color) !important;
}

/* Divider */
.ant-divider {
  margin: 10px 0 0;
}

/* Menu */
.ant-menu {
  justify-content: center;
  background: #ffffff;
  padding: 5px 0 15px;
  border: none;
}

.ant-menu .ant-menu-title-content {
  font-size: 16px;
  font-weight: 600;
}

/* Justify end */
.justify-end {
  justify-content: end;
}

/* Slider title */
.slider-title {
  font-family: "Times New Roman", Bold;
  font-weight: 600;
}

/* Card  */
.card-bg {
  background: var(--card-bg);
  border: none;
  border-radius: 0;
  text-align: center;
}

.card-bg .ant-card-body {
  min-height: 205px;
}

.card-bg .ant-card-meta-title {
  color: var(--dark-color);
  font-size: 20px;
  padding: 15px;
}

.card-bg .ant-card-meta-description {
  color: var(--dark-color) !important;
  line-height: 1.75;
  font-size: 16px;
}

.card-bg img {
  border-radius: 0 !important;
}

.card-bg .ant-card {
  border-radius: 0 !important;
}

.blog-card{
 background: var(--card-bg);
}

.card-bg-gray {
  background: var(--gray-bg);
}

.ant-card-meta-description {
  color: var(--dark-color) !important;
}

.product-card .ant-card-body {
  position: absolute;
  background: url('../src/assets//images/rectangle.png');
  background-size: auto;
  width: 80%;
  bottom: -4rem;
  text-align: center;
  left: 2.5rem;
  transform: rotate(-5deg);
  padding: 2rem 2rem;
  background-size: cover;
  height: 185px;
}

.floating-card {
  box-shadow: 0px 12px 24px #00000029;
  border: none;
  left: -3rem;
  border-radius: 0;
  padding: 2rem;
  /* bottom: -10rem; */
}

.floating-card-left {
  left: 3rem;
  z-index: 99;
}


.floating-card p{
 font-size: 16px;
}

.card-space{
  padding-bottom: 3rem;
  margin-bottom: 3rem;
}

.ant-card .ant-card-cover div{
  border-radius: 0!important;
}

.slick-arrow {
  position: absolute;
  top: 50%;
  width: 15px;
  height: 15px;
  transform: translateY(-50%);
  color: black!important;
  opacity: 1;
  background: transparent;
  padding: 0;
  line-height: 0;
  border: 0;
  outline: none;
  cursor: pointer;
  z-index: 999999!important;
  margin-left: -30px!important;
  font-size: 2rem!important;
}

.slick-arrow::after,
.slick-arrow::before{
  display: none!important;
}

.dark-color{
  color: var(--dark-color);
}

.primary-color{
  color: var(--primary-color);
}

/* Space */
.space {
  margin: 2.5rem 0rem 2.5rem 0rem;
}

.align-center{
  align-items: center;
}


/* Button */

.ant-btn-default{
  border: 1px solid var(--primary-color)!important;
  padding: 20px 30px;
  border-radius: 0;
}

.ant-btn-default:hover{
  color: #ffffff!important;
}

.blog-card .ant-btn{
  background: var(--card-bg);
}

.blog-card .ant-card-body{
  min-height: 205px;
  text-align: center;
}

.anticon-left{
  background: #592a26;
  border-radius: 24px;
  padding: 4px;
  font-size: 20px;
  color: white;
}
.anticon-right{
  background: #592a26;
  border-radius: 24px;
  padding: 4px;
  font-size: 20px;
  color: white;
  margin-left: 15px;
}

.left-side{
  right: -3rem;
  z-index: 9;
  left: 0;
}

.text-left{
  text-align: left;
}

.footer .ant-menu{
  background: var(--card-bg);
}

.footer_div{
  text-align: center;
  padding: 12px;
  background: #C1BFBB;
}


.footer_div span{
  color: var(--dark-color);
}


.ant-menu-item:hover::after{
  border-bottom-color: var(--primary-color)!important;
}

.MsoNormal{
  margin: 0!important;
  line-height: 2rem!important;
  padding: 0 0 1rem!important;
}

a:hover{
  color: var(--primary-color)!important;
}

.image-container {
  position: relative;
  /* overflow: hidden; */
}

.image {
  transition: opacity 0.5s ease-in-out;
}

.image-controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  pointer-events: none;
}

.control-btn {
  pointer-events: auto;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.control-btn:hover {
  opacity: 1;
}

.left-btn {
  position: absolute;
  left: 0;
}

.right-btn {
  position: absolute;
  right: 0;
}

.image-controls .anticon-left ,.image-controls .anticon-right{
  background: transparent!important;
} 

.ant-menu-submenu-open::after{
  border-bottom-color: var(--primary-color)!important;
}

svg {
  color: var(--primary-color) !important;
}

.ant-menu-submenu-selected::after{
  border-bottom-color: var(--primary-color)!important;
}

.blog-content p{
  padding: 0;

}

.share-buttons > * {
  margin: 0px 5px 10px 5px; /* Adjust the value to increase or decrease the spacing */
}

.ant-menu-submenu-title .anticon-ellipsis{
  font-size: 28px!important;
  padding-top: 10px;
}