/* Responsivness  media queries*/

@media screen and (max-width: 840px) {
    .floating-card {
        position: relative;
        left: 0!important;
        bottom: 0!important;
        right: 0!important;
      }
      .card-space{
        padding-bottom: 1rem;
        margin-bottom: 1rem;
      }
      .space {
        margin: 1rem 0rem 1rem 0rem;
      }
      .slider-bg{
        height: 100%!important;
      }
      .p-m-0{
        padding: 0!important;
      }
      .m-none{
        display: none;
      }
      .m-trending{
        display: block!important;
      }
      .m-trends{
        display: block!important;
      }
      .m-title{
        padding: 0px 1.5rem 0px 1.5rem!important;
      }
      .m-pt-0{
        padding-top: 0!important;
      }
      .m-p-2{
        padding: 15px!important;
      }
      .m-pt-2{
        padding-top: 15px!important;
      }
      .m-pb-0{
        padding-bottom: 0!important;
      }
      .m-pb-2{
        padding-bottom: 15px!important;
      }
      
      .m-mb-0{
        margin-bottom: 0!important;
      }
      .m-title .slider-title{
        font-size: 16px;
      }
      .mtm-0{
        margin-top: 0!important;
      }
  }
